<template>
  <v-card align="center" class="pa-2 my-2">
    <v-card-title
      class="text-subtitle-1 font-weight-bold d-flex align-center justify-space-between"
    >
      Alterar Senha
    </v-card-title>
    <v-form ref="form">
      <v-card-text>
        <div style="width: 350px">
          <v-text-field
            v-model="changePasswordForm.current_password"
            outlined
            label="Senha Atual"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[$rules.required, $rules.passwordMin]"
            :disabled="loadingSave"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
          />
        </div>
        <div style="width: 350px">
          <v-text-field
            v-model="changePasswordForm.new_password"
            outlined
            label="Nova Senha"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[$rules.required, $rules.passwordMin]"
            :disabled="loadingSave"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
          />
        </div>
        <div style="width: 350px">
          <v-text-field
            outlined
            label="Confirmar Senha"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[$rules.required, $rules.passwordMin, $rules.equalPassword(() => changePasswordForm.new_password)]"
            :disabled="loadingSave"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined :disabled="loadingSave">
          Cancelar
        </v-btn>
        <v-btn color="primary" @click="ChangePassword()" :loading="loadingSave">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "ChangePassword",

  data: () => ({
    showPassword: false,
    loadingSave: false,
    changePasswordForm: {
      current_password: "",
      new_password: "",
    },
  }),

  methods: {
    ChangePassword() {
      if (!this.$refs.form.validate()) {
        this.$showMessage("error", "Preencha todos os campos corretamente");
      } else {
        this.loadingSave = true;
        this.$axios
          .patch(`/user`, this.changePasswordForm)
          .then(() => {
            this.$showMessage("success", "Senha alterada");
            this.$refs.form.reset();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.$showMessage("warning", "Senha atual incorreta");
                return
              }
              if (error.response.status == 401) {
                this.$showMessage("warning", "A nova senha precisa ser diferente da atual");
                return
              }
            }
            this.$showMessage("error", "Falha na alteração de senha");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
  },
};
</script>
