<template>
  <v-card class="pa-2" :loading="loading">
    <slot />
    <v-card-title> Histórico de cobranças </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="charge in charges" :key="charge.id">
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <span class="font-weight-medium">
                {{ charge.num_consents }} consentimentos
              </span>
              <v-spacer />
              <span class="text-subtitle-2 font-weight-medium">
                R$ {{ $utils.formatMoney(charge.total_amount) }}
              </span>
              <v-divider vertical class="mx-2" />
              <span class="text-subtitle-2 font-weight-regular">
                {{ $utils.formatDate(charge.creation_datetime) }}
              </span>
              <v-divider vertical class="mx-2" />
              <v-icon :color="statusColor(charge.real_status)" class="ml-1">
                {{ statusIcon(charge.real_status) }}
              </v-icon>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="text-subtitle-1 d-flex align-center">
              <template v-if="charge.payment_type == 'BOLETO_PIX'">
                Cobrança {{ charge.translated_status }}
                <v-spacer />
                <div v-if="charge.status == 'ACTIVE'">
                  <v-btn
                    color="info" class="my-2" small depressed
                    target="_blank" :href="charge.link_boleto"
                  >
                    Ver Boleto / PIX
                    <v-icon right>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </template>
              <div v-else class="d-flex flex-column">
                <div class="text-caption">
                  Cobrança feita no Cartão de Crédito
                </div>
                <div class="text-body-1">
                  Pagamento {{ (charge.payments[0] || {}).translated_status || "Falhou" }}
                </div>
              </div>
            </div>
            <div class="text-caption">
              Criado em {{ $utils.formatDatetime(charge.creation_datetime) }}
            </div>
            <div class="d-flex justify-center mt-3" v-if="charge.subscription">
              <v-chip label>
                Cobrança criada por assinatura
              </v-chip>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PaymentHistory",

  props: {
    loading: {type: Boolean, default: false},
    charges: {type: Array, required: true},
  },

  data: () => ({
  }),

  methods: {
    statusIcon(status){
      switch (status){
        case 'ACTIVE':
          return 'mdi-alert-circle-outline';
        case 'CANCELLED':
          return 'mdi-close-circle';
        case 'NOT_AUTHORIZED':  case 'FAILED':
          return 'mdi-alert-circle';
        case 'MANUAL_RECONCILIATION': case 'CONFIRMED': case 'PAID':
          return 'mdi-check-bold';
      }
      return "mdi-alert-circle-outline";
    },
    statusColor(status){
      switch (status){
        case 'ACTIVE': return 'warning';
        case 'CANCELLED': case 'FAILED': case 'NOT_AUTHORIZED':
          return 'error';
        case 'MANUAL_RECONCILIATION': case 'CONFIRMED': case 'PAID':
          return 'success';
      }
      return "warning";
    },
  },
};
</script>
