<template>
  <div>
    <v-card :loading="loadingBalance">
      <v-card-title class="text-subtitle-1 font-weight-bold">
        Saldo Atual: {{ consentsBalance }} consentimentos disponíveis
        <v-spacer/>
        <!-- <v-btn color="success" depressed @click="showingPlans = true">
          Nova compra ou assinatura
        </v-btn> -->
        <div class="font-weight-medium">
          Entre em contato para novas compras
        </div>
      </v-card-title>
    </v-card>
    <v-divider class="my-2" />
    <v-row>
      <template v-if="initialLoading || charges.length > 0">
        <v-col cols="6">
          <v-card :loading="loadingSubscriptions">
            <v-card-title
              class="text-subtitle-1 font-weight-bold d-flex align-center justify-space-between"
            >
              <div>Assinaturas</div>
            </v-card-title>
            <v-card-text>
              <div v-if="subscriptions.length == 0">
                <div>
                  Nenhuma assinatura configurada
                </div>
              </div>
              <template v-for="subscription in subscriptions">
                <v-dialog :key="subscription.id" :persistent="loadingCancelSubscription" max-width="900px">
                  <template #activator="{ on }">
                    <v-card outlined v-on="on">
                      <v-card-title class="text-subtitle-1 d-flex justify-space-between align-center">
                        <div class="text-h6">
                          {{ subscription.num_consents }} consentimentos
                        </div>
                        <div class="text-center">
                          <div class="success--text" v-if="subscription.active">
                            Ativa
                          </div>
                          <div class="error--text" v-else>
                            Cancelada
                          </div>
                        </div>
                      </v-card-title>
                      <v-card-text class="grey--text text--darken-3">
                        <template v-if="subscription.charges == 0">
                          Nenhuma cobrança realizada.
                          <div v-if="subscription.active">
                            Aguarde até 5 minutos para a primeira cobrança ser processada.
                            Chame o suporte se estiver precisar de ajuda.
                          </div>
                        </template>
                        <template v-else>
                          {{ subscription.charges.length }}
                          cobrança{{ subscription.charges.length > 1 ? "s":"" }}
                          já realizada{{ subscription.charges.length > 1 ? "s":"" }}
                        </template>
                      </v-card-text>
                    </v-card>
                  </template>
                  <div>
                    <PaymentHistory :charges="subscription.charges">
                      <v-card-title>
                        Assinatura de {{ subscription.num_consents }} consentimentos
                      </v-card-title>
                      <v-card-text class="grey--text text--darken-3 pb-0">
                        <v-row no-gutters>
                          <v-col>
                            Criada em {{ $utils.formatDate(subscription.creation_datetime) }}
                            <br/>
                            Pagamento com {{subscription.payment_type == 'CREDIT_CARD' ? "Cartão de Crédito":"Boleto"}}
                            <br/>
                            <template v-if="subscription.payment_type == 'CREDIT_CARD'">
                              Número do cartão: #### #### #### {{ subscription.credit_card_details.last4CardNumber }}
                              <br/>
                              Vence em:
                              {{ subscription.credit_card_details.expirationMonth }}/{{ subscription.credit_card_details.expirationYear }}
                            </template>
                          </v-col>
                          <v-col>
                            <template v-if="subscription.active">
                              Próximo pagamento em {{$utils.formatDate(subscription.next_payment_date)}}                        
                              <br/>
                              <v-btn
                                color="warning" small depressed class="mt-1"
                                :loading="loadingCancelSubscription"
                                @click="cancelSubscription(subscription.id)"
                              >
                                Cancelar Assinatura
                              </v-btn>
                            </template>
                            <template v-else>
                              Assinatura Cancelada
                            </template>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </PaymentHistory>
                  </div>
                </v-dialog>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <PaymentHistory
            :loading="loadingCharges"
            :charges="charges"
          />
        </v-col>
      </template>
      <v-col cols="12" v-else>
        <Plans @open="$refs.purchaseDialog.open($event)" />
      </v-col>
    </v-row>

    <v-dialog v-model="showingPlans" max-width="1200px">
      <Plans @open="showingPlans = false; $refs.purchaseDialog.open($event)" />
    </v-dialog>

    <PurchaseDialog
      ref="purchaseDialog"
      @completed="
        loadCharges();
        loadSubscriptions();
        loadConsentsBalance();
      "
    />
  </div>
</template>

<script>
import PaymentHistory from "@/components/SettingsTabs/FinancialPage/PaymentHistory.vue";
import Plans from "@/components/SettingsTabs/FinancialPage/Plans.vue";
import PurchaseDialog from "@/components/SettingsTabs/FinancialPage/PurchaseDialog.vue";

export default {
  name: "Financial",

  components: {
    PurchaseDialog,
    PaymentHistory,
    Plans,
  },
  
  data: () => ({
    dialog: true,
    showingPlans: false,
    consentsLine: 50,
    loadingBalance: false,
    consentsBalance: '-',
    initialLoading: true,
    loadingCharges: false,
    loadingSubscriptions: false,
    loadingCancelSubscription: false,
    charges: [],
    subscriptions: [],
    charge: {
      num_consents: {},
      amount_per_consent: {},
      total_amount: {},
      payment_type: "",
      credit_card_details: {},
    },
  }),

  methods: {
    loadConsentsBalance(){
      this.loadingBalance = true;
      this.$axios.get('/consents-balance')
        .then((response) => {
          this.consentsBalance = response.data;
        })
        .finally(() => {
          this.loadingBalance = false;
          this.initialLoading = false;
        })
    },
    loadCharges(){
      this.loadingCharges = true;
      this.$axios.get('/charges')
        .then((response) => {
          this.charges = response.data
        })
        .catch(() => {
          this.$showMessage('error', "Ocorreu um erro inesperado ao carregar cobranças");
        })
        .finally(() => {
          this.loadingCharges = false;
        })
    },
    loadSubscriptions(){
      this.loadingSubscriptions = true;
      this.$axios.get('/subscriptions')
        .then((response) => {
          this.subscriptions = response.data
        })
        .catch(() => {
          this.$showMessage('error', "Ocorreu um erro inesperado ao carregar assinaturas");
        })
        .finally(() => {
          this.loadingSubscriptions = false;
          this.initialLoading = false;
        })
    },
    cancelSubscription(subscription_id){
      this.loadingCancelSubscription = true
      this.$axios.delete(`/subscription/${subscription_id}`)
        .then(() => {
          this.$showMessage('success', "Assinatura cancelada");
          this.loadSubscriptions()
        })
        .catch(() => {
          this.$showMessage('error', "Erro inesperado ao cancelar assinatura");
        })
        .finally(() => {
          this.loadingCancelSubscription = false;
        })
    },
  },

  mounted(){
    this.loadConsentsBalance();
    this.loadCharges();
    this.loadSubscriptions();
  },
};
</script>