<template>
  <v-dialog v-model="showing" persistent max-width="900px">
    <v-card>
      <v-card-title class="d-flex align-center text-subtitle-2">
        <div class="text-h5">
          {{
            chargeData.is_subscription ?
            "Configurando Assinatura Mensal":
            "Compra Avulsa"
          }}
        </div>
        <v-divider vertical class="mx-4" />
        <div>
          {{ chargeData.num_consents }} consentimentos
        </div>
        <v-divider vertical class="mx-4" />
        <div>
          Valor {{ chargeData.is_subscription ? "Mensal":"" }}:
          <span class="black--text">
            R$ {{ $utils.formatMoney(chargeData.amount) }}
          </span>
        </div>
        <v-spacer />
        <v-btn icon @click="showing = false; completed = false" :disabled="loading">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="completed" class="d-flex justify-center text-center">
        <v-sheet max-width="550px" class="mt-4">
          <div class="text-h5 text--success">
            Sua {{chargeData.is_subscription ? "assinatura":"aquisição"}} de Consentimentos
            foi processada com sucesso
          </div>
          <div v-if="!isCreditCard && !chargeData.is_subscription">
            <v-btn
              color="info" class="my-2" depressed
              target="_blank" :href="charge.link_boleto"
            >
              Ver Boleto / PIX
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
          <div class="mt-2">
            Fique atento ao seu e-mail para mais informações.
            Qualquer Dúvida ou Solicitação, basta entrar em contato com nossa equipe.
          </div>
          <v-btn
            color="success" depressed class="mt-4"
            @click="showing = false; completed = false"
          >
            ok
          </v-btn>
        </v-sheet>
      </v-card-text>
      <v-stepper v-else v-model="formStep" class="mt-1 transparent elevation-0 ma-0 pa-0">
        <v-stepper-header class="elevation-0 transparent mt-n6">
          <v-stepper-step :complete="formStep > 1" step="1"> Forma de Pagamento </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="formStep > 2" step="2"> Endereço de Cobrança </v-stepper-step>
            <v-divider />
          <template v-if="isCreditCard">
            <v-stepper-step :complete="formStep > 3" step="3"> Dados do cartão </v-stepper-step>
            <v-divider />
          </template>
          <v-stepper-step :step="isCreditCard ? 4:3">
            Revisão
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items class="mt-n2 mb-4">
          <v-stepper-content step="1" class="pa-0">
            <div class="text-center text-h6 mb-3">
              Selecione sua forma de pagamento:
            </div>
            <v-item-group mandatory class="mb-5 d-flex justify-center align-center">
              <v-item v-slot="{ active, toggle }">
                <v-sheet
                  @click="toggle(); data.payment_type = 'CREDIT_CARD'"
                  class="mr-1 pa-4 rounded" outlined style="cursor: pointer"
                  :elevation="active ? 4:0"
                >
                  <div
                    class="text-body-1 text-center mb-4 grey--text text--darken-4" 
                    :class="{'font-weight-bold': active}"
                  >
                    Cartão de Crédito
                  </div>
                  <v-img height="100px" contain src="@/assets/credit-card.jpg" />
                </v-sheet>
              </v-item>
              <v-item v-slot="{ active, toggle }" v-if="chargeData.amount > 150">
                <v-sheet
                  @click="toggle(); data.payment_type = 'BOLETO_PIX'"
                  class="mr-1 pa-4 rounded" outlined style="cursor: pointer"
                  :elevation="active ? 4:0"
                >
                  <div
                    class="text-body-1 text-center mb-4 grey--text text--darken-4" 
                    :class="{'font-weight-bold': active}"
                  >
                    Boleto / PIX
                  </div>
                  <v-img height="100px" contain src="@/assets/boleto.jpg" />
                </v-sheet>
              </v-item>
            </v-item-group>
            <div
              v-if="chargeData.amount < 150"
              class="text-center mb-3 text-body-2 grey--text text--darken-3"
            >
                * compras no boleto/pix somente para valores acima de R$ 150 reais
            </div>
            <div class="d-flex justify-center">
              <v-btn color="primary" outlined class="mr-2" @click="showing = false">
                Cancelar
              </v-btn>
              <v-btn color="primary" :disabled="!data.payment_type" @click="formStep++">
                Continuar
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <EditAddress
              isInline
              @cancel="formStep--"
              @saved="formStep++"
            />
          </v-stepper-content>

          <v-stepper-content step="3" class="pt-0 pb-2" v-if="isCreditCard">
            <CreditCardForm
              ref="creditCardForm"
              v-model="data.credit_card_hash"
              @previous="formStep--"
              @next="formStep = 4"
            />
          </v-stepper-content>

          <v-stepper-content class="pa-0" :step="isCreditCard ? 4:3">
            <v-form ref="revisionForm" class="d-flex justify-center text-center">
              <v-sheet max-width="600px">
                <div class="text-center text-h6">
                  Confirmação de {{chargeData.is_subscription ? "Assinatura":"Cobrança"}}
                </div>
                <v-checkbox
                  class="my-2" hide-details="auto"
                  :rules="[$rules.required]" :disabled="loading"
                  label="Estou ciente que a cobrança será processada pela plataforma Juno, e que minhas Informações de Contato e Endereço de Cobrança serão armazenados na plataforma."
                />
                <template v-if="isCreditCard">
                  <v-checkbox
                    class="my-2" hide-details="auto"
                    :rules="[$rules.required]" :disabled="loading"
                    label="Estou ciente que meu Cartão de Crédito informado será armazenado pela plataforma Juno."
                  />
                </template>
                <template v-else>
                  <v-checkbox
                    class="my-2" hide-details="auto"
                    :rules="[$rules.required]" :disabled="loading"
                    label="Estou ciente que para todas as cobranças em boleto, serão gerados Boletos Bancários pela plataforma Juno em meu nome, sem cobranças adicionais em caso de vencimento."
                  />
                </template>
                <template v-if="chargeData.is_subscription">
                  <v-checkbox
                    class="my-2" hide-details="auto"
                    :rules="[$rules.required]" :disabled="loading"
                    label="Estou ciente que essa cobrança será processada mensalmente até que seja solicitado o cancelamento."
                  />
                </template>
                <div class="text-center mt-4">
                  <v-btn color="secondary" :disabled="loading" class="mr-2" outlined @click="formStep--">
                    Voltar
                  </v-btn>
                  <v-btn color="success" :loading="loading" @click="createCharge()">
                    Confirmar {{ chargeData.is_subscription ? "Assinatura":"Compra" }}
                  </v-btn>
                </div>
              </v-sheet>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import EditAddress from '@/components/SettingsTabs/AccountDataForms/EditAddress.vue';
import CreditCardForm from '@/components/SettingsTabs/FinancialPage/CreditCardForm.vue';

export default {
  name: "PurchaseDialog",

  components: { EditAddress, CreditCardForm },

  data: () => ({
    chargeData: {
      amount: 0,
    },
    showing: false,
    loading: false,
    formStep: 1,
    completed: false,
    data: {
      num_consents: 0,
      payment_type: 'CREDIT_CARD',
      credit_card_hash: undefined,
    },
    charge: {},
  }),

  computed: {
    isCreditCard(){
      return this.data.payment_type == 'CREDIT_CARD';
    },
  },

  methods: {
    open(chargeData) {
      this.chargeData = chargeData;
      this.data.num_consents = chargeData.num_consents;
      this.$nextTick(() => {
        this.showing = true;
      });
    },
    createCharge(){
      if (this.$refs.revisionForm.validate()){
        this.loading = true;
        this.$axios.post(this.chargeData.is_subscription ? '/subscription':'/charge', this.data)
        .then((response) => {
          if (response.data)
            this.charge = response.data;
          this.formStep = 1;
          this.completed = true;
          this.$emit('completed');
        })
        .catch((error) => {
          if (error.response){
            if (error.response.status == 400){
              try {
                const junoMessage = error.response.data.detail.details[0].message;
                if (junoMessage){
                  this.$showMessage(
                    'warning',
                    `Erro da Juno: <b>${junoMessage}</b>`
                  );
                  return;
                }
              } catch (error) {
                console.log("ERROR:", error.response);
              }
            }
          }
          this.$showMessage('error', "Ocorreu um erro inesperado");
          this.$refs.creditCardForm.generateHash();
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>
