<template>
  <div>
    <v-card :elevation="hideCard ? 0 : 2">
      <v-card-text
        style="min-height: 0;/*500px*/"
        :class="{ 'ma-0': hideCard, 'pa-0': hideCard }"
        class="grey--text text--darken-3"
      >
        <div>
          <div class="mt-5 text-h6">
            O eCONSENSO adapta-se a Realidade e a Necessidade do seu
            Empreendimento
          </div>
          <!-- <p class="mt-2 text-body-2">
            Você irá investir somente na quantidade de Consentimentos que
            utilizar. E fique tranquilo, os Consentimentos não possuem validade
            para uso.
          </p> -->
        </div>

        <v-form
          v-if="!showingExplanation"
          ref="form"
          class="d-flex align-start"
          @submit.prevent="getPlans()"
        >
          <v-text-field
            v-model.number="num_consents"
            outlined
            class="mr-2"
            dense
            label="Quantos Consentimentos precisará?"
            type="number"
            hide-details="auto"
            :rules="[
              $rules.required,
              (v) => v >= 10 || 'A compra mínima é de 10 consentimentos',
            ]"
          />
          <v-btn color="primary" class="py-5" elevation="1" type="submit">
            Calcular
          </v-btn>
        </v-form>

        <v-fade-transition>
          <div
            key="loading"
            v-if="loadingPlans"
            class="pr-8"
            style="position: absolute; width: 100%"
          >
            <v-row dense>
              <v-col cols="3" v-for="item in 4" :key="item">
                <v-card>
                  <v-skeleton-loader
                    align="center"
                    type="card-heading, table-cell, divider, card-heading, list-item-two-line, divider, card-heading"
                  />
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
        
        <b>
          Entre em contato para mais informações.
        </b>

        <v-fade-transition group leave-absolute v-if="false">
          <div
            key="explanation"
            v-if="showingExplanation"
            class="
              my-2
              grey--text
              text--darken-4
              d-flex
              justify-center
              align-center
            "
            :class="{ 'flex-wrap': $vuetify.breakpoint.xs }"
          >
            <!--  -->
            <div>
              <v-img
                src="@/assets/landing-page/calculator.png"
                width="300px"
                height="300px"
              />
            </div>
            <!-- <div class="mx-2" style="max-width: 300px">
            </div> -->
            <div class="ml-8 fill-height d-flex flex-column justify-end">
              <div class="text-h6">Utilize nossa Calculadora</div>
              <p class="mt-2 mb-0">
                Você poderá visualizar o quanto irá Investir no seu eCONSENSO.
              </p>
              <p>
                Digite a quantidade de Consentimentos que iremos lhe apresentar
                o investimento por quantidade, e as condições de cada opção.
              </p>
              <v-form
                ref="form"
                class="d-flex align-start"
                @submit.prevent="getPlans()"
              >
                <v-text-field
                  v-model.number="num_consents"
                  outlined
                  class="mr-2"
                  dense
                  label="Quantos Consentimentos precisará?"
                  hint="A compra mínima é de 10 consentimentos"
                  type="number"
                  :rules="[
                    $rules.required,
                    (v) => v >= 10 || 'A compra mínima é de 10 consentimentos',
                  ]"
                />
                <v-btn color="primary" class="py-5" elevation="1" type="submit">
                  Calcular
                </v-btn>
              </v-form>
              <div
                class="
                  text-subtitle-2
                  font-weight-regular
                  text-start
                  grey--text
                  text--darken-3
                  pb-2
                "
              >
                * os consentimentos não utilizados ficarão de bônus na conta
              </div>
            </div>
          </div>

          <div key="plans" v-if="showingPlans">
            <p class="grey--text text--darken-4">
              Para a sua necessidade de Consentimentos, temos
              {{ buyCards.length }} opções de Investimento no eCONSENSO:
            </p>
            <v-row dense>
              <v-col
                v-for="buyCard in buyCards"
                :key="buyCard.title"
                cols="12"
                :sm="cardsSize"
              >
                <v-card
                  color="accent white--text"
                  class="
                    pt-8
                    pb-6
                    px-5
                    d-flex
                    flex-column
                    text-center
                    fill-height
                  "
                >
                  <div
                    v-if="buyCard.discount_percentage > 0"
                    class="rounded-circle mr-n3 mt-n13 pa-1"
                    style="position: absolute; right: 0"
                  >
                    <v-icon large class="primary--text">mdi-sale</v-icon>
                  </div>

                  <div class="text-h5 font-weight-bold text-center">
                    <template v-if="buyCard.is_subscription">
                      Assinatura Mensal
                    </template>
                    <template v-else> Compra Avulsa </template>
                  </div>

                  <v-divider class="my-2 accent darken-1" style="width: 100%" />

                  <div
                    class="text-h4 font-weight-medium"
                    style="width: 100%"
                    align="center"
                  >
                    {{ buyCard.num_consents }}
                  </div>
                  <div class="text-caption">consentimentos</div>

                  <v-divider class="my-2 accent darken-1" style="width: 100%" />

                  <div
                    v-if="buyCard.discount_percentage > 0"
                    class="
                      text-center text-subtitle-1 text-decoration-line-through
                      mb-n4
                    "
                  >
                    R$
                    {{ $utils.formatMoney(buyCard.amount + buyCard.discount_amount) }}
                  </div>

                  <div class="flex-grow-1 d-flex flex-column justify-center">
                    <div class="text-h4 mt-2">R$ {{ $utils.formatMoney(buyCard.amount) }}</div>
                    <div>
                      <template v-if="buyCard.discount_percentage > 0">
                        {{ buyCard.discount_percentage }}% de desconto
                      </template>
                    </div>
                  </div>

                  <v-divider class="my-2 accent darken-1" style="width: 100%" />

                  <div
                    class="text-h5 font-weight-regular"
                    style="width: 100%"
                    align="center"
                  >
                    R$ {{ $utils.formatMoney(buyCard.amount / buyCard.num_consents) }}
                  </div>
                  <div class="text-caption">por consentimento</div>

                  <v-btn
                    v-if="purchaseAvailable"
                    class="mt-5"
                    color="primary"
                    elevation="1"
                    @click="$emit('open', buyCard)"
                  >
                    <template v-if="buyCard.is_subscription">
                      Assinar
                    </template>
                    <template v-else> Comprar </template>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </v-card-text>
      <div
        v-if="showingPlans"
        class="
          text-subtitle-2
          font-weight-regular
          text-center
          grey--text
          text--darken-3
          mt-5
          pb-2
        "
      >
        * os consentimentos não utilizados ficarão de bônus na conta
      </div>
      <div
        align="center"
        class="mt-4 pb-5"
        v-if="showingPlans && !purchaseAvailable"
      >
        <v-btn
          color="primary"
          x-large
          @click="
            (formDialog.data.stage = 'Educação - LGPD'),
              (formDialog.data.tags = ['LP Institucional E-Book']),
              (formDialog.active = true)
          "
        >
          Solicite seu teste gratuito
        </v-btn>
        <div class="text-body-1 font-weight-medium mt-1">
          50 Consentimentos Grátis
        </div>
      </div>
    </v-card>

    <v-dialog
      v-model="formDialog.active"
      max-width="500px"
      :persistent="formDialog.loading"
    >
      <v-card>
        <div align="end">
          <v-btn
            icon
            @click="formDialog.active = false"
            :disabled="formDialog.loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0 justify-center">
          <div align="center" class="subtitle1 ma-5 font-weight-bold break">
            Falta muito pouco para você ter acesso ao seu TESTE GRATUITO DO
            eCONSENSO. Responda ao formulário abaixo e receba no seu e-mail
            todas as orientações.
          </div>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form ref="leadsForm">
            <v-text-field
              label="Nome *"
              v-model="formDialog.data.name"
              dense
              class="mt-2"
              :rules="[$rules.required]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E-Mail *"
              v-model="formDialog.data.email"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.email]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Telefone *"
              v-model="formDialog.data.phone"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.phone]"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="(00) 00000-0000"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Qual a sua Empresa atualmente?"
              v-model="formDialog.data.company"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E qual a sua posição nessa Empresa?"
              v-model="formDialog.data.company_position"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-checkbox
              label="Concordo em receber comunicações"
              :disabled="formDialog.loading"
              :rules="[(v) => !!v || 'Você deve concordar para continuar']"
            />
            <p class="text-subtitle-2 grey--text text--darken-1">
              Uma mensagem com mais informações será enviada para seu e-mail.
              Confira também sua caixa de spam.
            </p>
            <div align="center" justify="center">
              <v-btn
                class="my-2"
                block
                large
                color="success"
                :loading="formDialog.loading"
                @click="sendForm()"
              >
                Enviar
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Plans",

  props: {
    purchaseAvailable: { type: Boolean, default: true },
    hideCard: { type: Boolean, default: false },
  },

  data: () => ({
    num_consents: undefined,
    buyCards: [],
    loadingPlans: false,
    showingPlans: false,
    showingExplanation: true,
    formDialog: {
      active: false,
      loading: false,
      data: {
        name: "",
        email: "",
        phone: "",
        company: "",
        company_position: "",
      },
    },
  }),

  computed: {
    cardsSize() {
      return Math.floor(12.0 / this.buyCards.length);
    },
  },

  methods: {
    getPlans() {
      if (this.$refs.form.validate()) {
        this.showingExplanation = false;
        this.showingPlans = false;
        this.loadingPlans = true;
        this.$axios
          .get(`/plans/${this.num_consents}`)
          .then((response) => {
            this.buyCards = response.data;
            this.showingPlans = true;
          })
          .catch((error) => {
            if (!this.showingPlans) {
              this.showingExplanation = true;
            }
            if (error.response) {
              if (error.response.status == 406) {
                this.$showMessage(
                  "warning",
                  "A compra mínima é de 10 consentimentos"
                );
                return;
              }
            }
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingPlans = false;
          });
      }
    },

    sendForm() {
      if (this.$refs.leadsForm.validate()) {
        this.formDialog.loading = true;
        const queryParams = Object.entries(this.$route.query).map(
          ([key, val]) => `${key}=${val}`
        );
        this.$axios
          .post("/lead", {
            ...this.formDialog.data,
            stage: "Solicitação de Agendamento",
            tags: ["LP Teste Gratuito", ...queryParams],
          })
          .then(() => {
            this.$showSuccessMessage(
              "Você está prestes a ter acesso ao seu Teste Gratuito!",
              "Enviamos ao seu e-mail mais informações e procedimentos para realizar o seu Teste Gratuito. Fique atento, e caso possua interesse estamos disponíveis por Whatsapp/Telefone no número (48) 4042-3534. Até mais!"
            );
            this.$refs.leadsForm.reset();
            this.formDialog.active = false;
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado ao salvar os dados. Tente novamente ou contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.formDialog.loading = false;
          });
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
  },
};
</script>
