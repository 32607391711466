<template>
  <v-card color="grey lighten-2">
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="text-h5 font-weight-medium">Suas Informações</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-2">
      <InCharge />
      <BasicData />
      <EditAddress />
      <ChangePassword />
    </v-card-text>
  </v-card>
</template>
<script>
import InCharge from "@/components/SettingsTabs/AccountDataForms/InCharge";
import EditAddress from "@/components/SettingsTabs/AccountDataForms/EditAddress";
import ChangePassword from "@/components/SettingsTabs/AccountDataForms/ChangePassword";
import BasicData from "@/components/SettingsTabs/AccountDataForms/BasicData";

export default {
  name: "AccountData",

  components: {
    InCharge,
    BasicData,
    EditAddress,
    ChangePassword,
  },

  data: () => ({
    showPassword: false,
    editInfo: false,
    showing: false,
    numbers: ["1", "2", "3", "4", "5"],
    years: ["2021", "2022", "2023", "2024", "2025"],
    e1: 1,
  }),

  methods: {
    open() {
      this.showing = true;
    },
  },

};
</script>