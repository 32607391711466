<template>
  <v-card :class="isInline ? 'mx-4':'pa-2 my-2'" :elevation="isInline ? 0:2">
    <v-card-title class="text-subtitle-1 font-weight-bold" v-if="!isInline">
      Edição de Endereço de Cobrança
    </v-card-title>
    <v-form ref="form" @submit.prevent="updateUserAdress()">
      <v-card-text :class="{ 'ma-0': isInline, 'pa-0': isInline }">
        <div class="d-flex">
          <v-text-field
            v-model="userAddress.postCode"
            outlined
            label="CEP *"
            class="pa-1"
            hide-details="auto"
            :disabled="loadingAddress || loadingSave"
            :rules="[$rules.required, $rules.cep]"
            v-mask="'#####-###'"
          />
          <v-autocomplete
            v-model="userAddress.state.id"
            :items="states"
            item-text="name"
            item-value="id"
            outlined
            label="Estado *"
            class="pa-1"
            hide-details="auto"
            :disabled="loadingAddress || loadingSave || loadingStates"
            :loading="loadingStates"
            :rules="[$rules.required]"
          />
          <v-autocomplete
            v-model="userAddress.city_id"
            :items="cities"
            item-text="name"
            item-value="id"
            outlined
            label="Cidade *"
            class="pa-1"
            hide-details="auto"
            :loading="loadingCities"
            :disabled="loadingStates || loadingCities || loadingAddress || loadingSave"
            :rules="[$rules.required]"
            :no-data-text="cities.length == 0 ? 'Selecione primeiro um estado' : 'Nenhuma cidade encontrada'"
          />
        </div>
        <div class="d-flex">
          <v-text-field
            v-model="userAddress.neighborhood"
            outlined
            label="Bairro *"
            class="pa-1"
            hide-details="auto"
            :disabled="loadingAddress || loadingSave"
            :rules="[$rules.required]"
          />
          <v-text-field
            v-model="userAddress.street"
            outlined
            label="Logradouro/Rua/Avenida *"
            class="pa-1"
            hide-details="auto"
            :disabled="loadingAddress || loadingSave"
            :rules="[$rules.required]"
          />
        </div>
        <div class="d-flex">
          <v-text-field
            v-model="userAddress.number"
            outlined
            label="Número *"
            class="pa-1"
            hide-details="auto"
            :disabled="loadingAddress || loadingSave"
            :rules="[$rules.required]"
          />
          <v-text-field
            v-model="userAddress.complement"
            outlined
            label="Complemento"
            class="pa-1"
            hide-details="auto"
            :disabled="loadingAddress || loadingSave"
          />
        </div>
      </v-card-text>
      <v-card-actions class="px-1">
        <v-spacer />
        <v-btn
          outlined @click="resetForm()"
          :disabled="loadingSave || loadingAddress"
        >
          {{ isInline ? "Voltar":"Cancelar" }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          :disabled="loadingAddress"
          :loading="loadingSave"
        >
          {{ isInline ? "Continuar":"Salvar" }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "EditAdress",

  props: {
    isInline: {type: Boolean, default: false},
  },

  data: () => ({
    loadingSave: false,
    loadingAddress: false,
    loadingStates: false,
    loadingCities: false,
    userAddress: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: {},
      city_id: undefined,
      state: {},
      postCode: "",
    },
    cities: [],
    states: [],
  }),

  methods: {
    updateUserAdress() {
      if (this.$refs.form.validate()){
        this.loadingSave = true;
        this.$axios
          .put("/address" , this.userAddress)
          .then((response) => {
            this.$showMessage("success", "Endereço de cobrança atualizado");
            this.getUserAddress();
            this.$emit('saved');
          })
          .catch((error) => {
            this.$showMessage("error", "Ocorreu um erro inesperado na atualização do endereço de cobrança");
          })
          .finally(() => {
            this.loadingSave = false;
          })
      } else {
        this.$showMessage("warning", "Preencha os campos do endereço corretamente");
      }
    },
    getUserAddress() {
      this.loadingAddress = true;
      this.$axios
        .get("/address")
        .then((response) => {
          let address = response.data
          if (address){
            if (!address.state){
              address.state = {id: undefined}
            }
            if (!address.city){
              address.city = {id: undefined}
            }
            this.userAddress = address;
          }
        })
        .catch((error) => {
          this.$showMessage("error", "Falha inesperada no carregamento do endereço de cobrança");
        })
        .finally(() => {
          this.loadingAddress = false;
        });
    },
    loadStates() {
      this.loadingStates = true;
      this.$axios
        .get("/states")
        .then((response) => {
          this.states = response.data;
          this.loadingStates = false;
        })
        .catch(() => {
          this.$showMessage("error", "Falha inesperada no carregamento da lista de estados");
        });
    },
    loadCities(){
      if (!this.userAddress || !this.userAddress.state)
        return
      const state = this.userAddress.state
      if (!state.id){
        this.cities = []
      } else {
        this.loadingCities = true
        this.$axios.get(`/cities/${state.id}`).then((response) => {
          this.cities = response.data;
          this.loadingCities = false;
        })
        .catch(() => {
          this.$showMessage("error", "Falha inesperada no carregamento da lista de cidades");
        })
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.getUserAddress();
      this.$emit('cancel');
    },
  },
  watch: {
    'userAddress.state.id'(newstate, oldstate){
      if (newstate != oldstate && oldstate != undefined){
        this.userAddress.city_id = undefined
      }
      this.loadCities()
    },
  },

  mounted() {
    this.getUserAddress();
    this.loadStates();
  },

};
</script>
