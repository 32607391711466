<template>
  <v-card class="my-2 pa-2">
    <v-card-title class="text-subtitle-1 font-weight-bold d-flex align-center justify-space-between">
      Dados da Conta

      <v-expand-transition>
        <v-btn
          color="info darken-2"
          small outlined text
          @click="editingInfo = true"
          v-if="!editingInfo && !loadError && !loadingData"
        >
          Editar Informações
        </v-btn>
      </v-expand-transition>

      <v-expand-transition>
        <v-alert v-if="loadError" color="error" dense outlined text style="margin-bottom: 0">
          <div class="d-flex justify-space-between align-center">
            <span class="text-body-2 font-weight-medium">
              Erro ao carregar dados da conta
            </span>
            <v-btn color="info" small class="ml-4" @click="loadUserData()">
              tentar novamente
            </v-btn>
          </div>
        </v-alert>
      </v-expand-transition>

      <v-expand-transition>
        <v-progress-circular
          color="primary" indeterminate
          v-if="loadingData"
        />
      </v-expand-transition>
      
    </v-card-title>
    <v-form ref="form">
      <v-card-text class="pt-0 pb-0">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Razão Social *"
              v-model="userData.name"
              :disabled="loadingSave"
              :readonly="!editingInfo" :filled="editingInfo"
              :rules="[$rules.required]"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="E-Mail *"
              v-model="userData.email"
              :readonly="!editingInfo" :filled="editingInfo"
              :rules="[$rules.required, $rules.email]"
              :disabled="loadingSave"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Telefone *"
              v-model="userData.phone"
              :disabled="loadingSave"
              v-mask="['(##) ####-####', '(##) #####-####']"
              :rules="[$rules.required, $rules.phone]"
              :readonly="!editingInfo" :filled="editingInfo"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="CNPJ *"
              v-model="userData.cnpj"
              :disabled="loadingSave"
              v-mask="['##.###.###/####-##']"
              :readonly="!editingInfo" :filled="editingInfo"
              :rules="[$rules.required, $rules.cnpj]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-expand-transition>
        <v-card-actions v-if="editingInfo" class="pt-0">
          <v-spacer />
          <v-btn outlined :disabled="loadingSave" @click="editingInfo = false; loadUserData()">
            Cancelar
          </v-btn>
          <v-btn class="primary" :loading="loadingSave" :disabled="loadingData" @click="saveUserData()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-expand-transition>
    </v-form>
  </v-card>
</template>
<script>
export default {
  name: "BasicData",

  data: () => ({
    editingInfo: false,
    loadingData: false,
    loadingSave: false,
    loadError: false,
    userData: {},
  }),

  methods: {
    loadUserData(){
      this.loadError = false
      this.loadingUser = true
      this.$axios('/user')
        .then((response) => {
          this.userData = response.data
        })
        .catch((error) => {
          this.loadError = true
        })
        .finally(() => {
          this.loadingUser = false
        })
    },
    saveUserData(){
      if (this.$refs.form.validate()){
        this.loadingSave = true
        this.$axios.put('/user', this.userData)
          .then(() => {
            this.editingInfo = false
            this.loadUserData()
            this.$showMessage('success', "Novos dados da conta salvos")
          })
          .catch(() => {
            this.$showMessage('error', "Erro inesperado ao salvar dados da conta")
          })
          .finally(() => {
            this.loadingSave = false
          })
      }
    },
  },

  mounted(){
    this.loadUserData()
  },
};
</script>
