<template>
  <v-card elevation="4">
    <v-card-title class="text-h5 font-weight-medium">
      Configurações
    </v-card-title>
    <v-divider />
    <v-card-text>
      <div class="d-flex flex-wrap justify-space-between align-center pb-2">
        <div class="d-flex align-center">
          <!-- <v-avatar class="black" size="100">
            <img />
          </v-avatar> -->
          <div class="ml-2">
            <div class="text-h4 font-weight-bold black--text">
              {{ $store.state.user.name }}
            </div>
            <div class="text-subtitle-1">
              {{ $store.state.user.email }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-around mb-2">
          <v-sheet rounded elevation="2" class="d-flex" style="overflow: hidden">
            <v-btn
              color="primary"
              class="rounded-0" depressed
              target="_blank" :href="`${$axios.defaults.baseURL}/privacy-policies/${$store.state.user.id}.pdf`"
            >
              Politicas de Privacidade da Empresa
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
            <v-divider vertical />
            <v-tooltip top :open-delay="500">
              <span>
                Enviar nova Política de Privacidade
              </span>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  class="rounded-0 px-2" style="min-width: 0"
                  @click="$refs.privacyPolicyDialog.open()" depressed
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </v-sheet>
        </div>
          <!-- <div class="text-h5 font-weight-bold black--text">
            Aproveite ainda mais o eConsenso
          </div>
          <div class="grey-">
            Texto de propaganda curta texto texto texto texto texto texto texto
          </div>
          <div>
            <div>
              <v-btn color="grey lighten-2" tile> Vamos lá! </v-btn>
              Ignorar por enquanto!
            </div>
          </div> -->
      </div>
      
      <v-tabs grow center-active show-arrows color="primary" style="width: 100%">
        <v-tab>
          <v-icon left> mdi-cash </v-icon>
          Financeiro
        </v-tab>
        <v-tab>
          <v-icon left> mdi-account-outline </v-icon>
          Dados da Empresa
        </v-tab>
        <v-tab>
          <v-icon left> mdi-form-select </v-icon>
          Termos e Condições
        </v-tab>

        <v-tab-item class="pa-2">
          <Financial />
        </v-tab-item>
        <v-tab-item class="pa-2">
          <v-card flat>
            <v-card-text>
              <AccountData/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              Termos e Condições
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      
      
    </v-card-text>
    <PrivacyPolicyDialog ref="privacyPolicyDialog" />
    
  </v-card>
</template>

<script>
import Financial from "@/components/SettingsTabs/Financial.vue";
import AccountData from "@/components/SettingsTabs/AccountData.vue";
import PrivacyPolicyDialog from "@/components/SettingsTabs/PrivacyPolicyDialog.vue";

export default {
  name: "Settings",

  components: {
    AccountData,
    Financial,
    PrivacyPolicyDialog,
  },

  data: () => ({
  }),
  
};
</script>
