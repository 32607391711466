<template>
  <v-dialog v-model="showing" max-width="700px" :persistent="loadingData">
    <v-card class="pa-2">
      <v-card-title class="d-flex align-center justify-space-between">
        <div>Políticas de Privacidade da Empresa</div>
        <div>
          <v-btn @click="showing = false" icon color="black">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-file-input
          :disabled="loadingData"
          label="Selecione o arquivo PDF da nova Política de Privacidade"
          accept="application/pdf"
          outlined
          dense
          :rules="[$rules.required]"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="showing = false" :disabled="loadingData">
          Cancelar
        </v-btn>
        <v-btn color="primary" :loading="loadingData">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PrivacyPolicyDialog",

  data: () => ({
    showing: false,
    loadingData: false,
  }),

  methods: {
    open() {
      this.showing = true;
    },
  },

};
</script>
