<template>
  <v-form ref="creditCardForm" v-if="!!checkout">
    <v-text-field
      label="Nome Completo / Razão Social (conforme impresso no cartão) *"
      v-model="creditCard.holderName"
      filled color="secundary" :disabled="loadingHash" hide-details="auto"
      :rules="[
        $rules.required,
      ]"
    />
    <v-row no-gutters>
      <v-col>
        <v-text-field
          label="Número do Cartão *"
          placeholder="0000 0000 0000 0000"
          v-mask="'#### #### #### ####'" v-model="creditCard.cardNumber"
          filled color="secundary" :disabled="loadingHash" hide-details="auto"
          :rules="[
            $rules.required,
            (v) => checkout.isValidCardNumber(v) || 'O número do cartão de crédito é inválido',
          ]"
        />
      </v-col>
      <v-divider vertical />
      <v-col cols="5">
        <v-text-field
          label="Código de Segurança *"
          v-mask="'####'" v-model="creditCard.securityCode"
          filled color="secundary" :disabled="loadingHash" hide-details="auto"
          :rules="[
            $rules.required,
            (v) => checkout.isValidSecurityCode(this.creditCard.cardNumber, v) || 'O código de segurança para esse número de cartão é inválido',
          ]"
        />
      </v-col>
    </v-row>
    <v-text-field
      label="Vencimento *"
      v-mask="'##/####'" v-model="expirationDate"
      filled color="secundary" :disabled="loadingHash" hide-details="auto"
      placeholder="00/0000"
      :rules="[
        $rules.required,
        (v) => (v.split('/').length == 2 && checkout.isValidExpireDate(v.split('/')[0], v.split('/')[1])) || 'Vencimento Inválido',
      ]"
    />
    <v-alert v-model="invalidCardDataAlert" type="warning" class="mt-2" transition="scale-transition">
      Os dados de cartão são inválidos.
    </v-alert>
    <div class="d-flex justify-end mt-3">
      <v-btn color="primary" outlined class="mr-2" :disabled="loadingHash" @click="$emit('previous')">
        Voltar
      </v-btn>
      <v-btn color="primary" @click="generateHash()" :loading="loadingHash">
        Continuar
      </v-btn>
    </div>
  </v-form>
</template>

<script>
const PUBLIC_TOKEN = (process.env.NODE_ENV == 'production') ? 
  "77B48AC9317F02DD360D5FD3A5D4251D4679819AD383E7279BAFAD33FAE5EC0E9FB055FF80A65315":
  "0C2B2CD9825CB2324C49984F56DA787C1FA1D5FB6F1B9B051EC184C4CAA59DDC"

export default {
  name: "CreditCardForm",
  props: {
    value: {required: true},
  },
  data: () => ({
    checkout: undefined,
    creditCard: {
      cardNumber: "",
      holderName: "",
      securityCode: "",
    },
    loadingHash: false,
    expirationDate: "",
    invalidCardDataAlert: false,
  }),
  computed: {
    cardType(){
      if (this.checkout)
        return this.checkout.getCardType(this.creditCard.cardNumber.replace(/\s/g, ""))
      return 'none'
    },
    cardData(){
      if (this.expirationDate.length == 7){
        let expirationDate = this.expirationDate.split('/')
        let data = {
          ...this.creditCard,
          expirationMonth: expirationDate[0],
          expirationYear: expirationDate[1],
        }
        data.cardNumber = data.cardNumber.replace(/\s/g, "")
        return data
      }
      return {}
    },
  },
  watch: {
    creditCard: {
      deep: true,
      handler(){
        this.invalidCardDataAlert = false
      },
    },
    expirationDate(){
      this.invalidCardDataAlert = false
    },
    cardData: {
      deep: true,
      handler(){
        this.invalidCardDataAlert = false
      },
    },
  },
  methods: {
    generateHash(){
      if (this.$refs.creditCardForm.validate()){
        this.loadingHash = true;
        this.checkout.getCardHash(
          this.cardData,
          (cardHash) => {
            this.invalidCardDataAlert = false
            this.loadingHash = false;
            this.$emit('input', cardHash);
            this.$emit('next');
          },
          (error) => {
            this.invalidCardDataAlert = true
            console.log("CardHashError", error)
            this.loadingHash = false;
          }
        )
      }
    },
  },
  created(){
    const junoScript = document.createElement('script');
    junoScript.setAttribute('type', 'text/javascript');
    if (process.env.NODE_ENV == 'production'){
      junoScript.setAttribute('src', 'https://www.boletobancario.com/boletofacil/wro/direct-checkout.min.js');
    } else {
      junoScript.setAttribute('src', 'https://sandbox.boletobancario.com/boletofacil/wro/direct-checkout.min.js');
    }
    junoScript.onload = () => {
      this.checkout = new DirectCheckout(PUBLIC_TOKEN, (process.env.NODE_ENV == 'production'))
    }
    document.body.appendChild( junoScript );
  },
}
</script>
